.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.condenser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .reload-bar {
    flex: 0 0 auto;
    padding: 15px 20px;
    display: flex;
    justify-content: flex-end;
    .reload-button {
      width: 60px;
      height: 60px;
      background: #d8d8d8 url('snaptext_refresh.svg') no-repeat center / 40px;
      border-radius: 6px;
    }
  }
  .content-bar{
    display: flex;
    justify-content: center;
    .content {
      flex: 0 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: calc(100% - 40px);
      .snaptext-label {
        flex: 0 0 auto;
        font-size: 24px;
        color: #5d6676;
        margin-bottom: 10px;
      }
      .snaptext-value {
        border-radius: 0;
        padding: 10px;
        font-size: 28px;
        line-height: 28px;
        color: black;
        margin-bottom: 15px;
        overflow-wrap: break-word;
        &.snap-id {
          border: 1px solid #D8D8D8;
        }
        &.snap-text {
          background: #F2F2F2;
          border: 1px solid #B6B78E;
        }
      }
      .boxed-input {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        .input-box {
          flex: 0 0 auto;
          border: 1px solid black;
          border-radius: 0;
          box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.5);
          width: 70px;
          height: 90px;
          margin-right: 12px;
          text-align: center;
          font-size: 60px;
          text-transform: uppercase;
          &:nth-child(3) {
            margin-right: 40px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .text-copied {
        color: #14B833;
        font-size: 18px;
        margin-top: 10px;
        font-style: italic;
      }
      .fetch-error {
        color: red;
        font-size: 18px;
        margin-top: 10px;
        font-style: italic;
      }
      $button-background: #2AA4E6;
      $button-background-disabled: #d8d8d8;
      .enter-button-container {
        flex: 0 0 auto;
        display: flex;
        margin: 15px 0;
        .enter-button {
          flex: 1 0 auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          height: 45px;
          background-color: $button-background-disabled;
          .text {
            font-size: 20px;
            color: white;
          }
          &:not(.disabled) {
            background: $button-background;
            &:hover {
              background: $button-background;
            }
            &:active {
              background: $button-background;
            }
          }
        }
      }
    }
  }
}
