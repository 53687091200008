body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18pt;
}

th {
  background-color: #f2f2f2
}

td{
  padding-left: 1em;
  padding-right: 1em;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.copied-feedback {
  color: #25bd46
}